import { useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import Swal from 'root-components/swal/swal';
import { useStyles } from '../app.styles';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFilters from 'root-components/cardfilters/card-filters';
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import FilterModel from '@bubotech/sumora-react-components/lib/cardfilters/models/filter';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import GrupoEconomico from 'root-models/grupo-economico';
import GrupoEconomicoAPI from 'root-resources/api/grupo-economico';
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';
import { TableOrder } from 'root-models/tableorder';

export type GrupoEconomicoPropType = {};

/**
 * Tela de listagem de grupo econômico
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {GrupoEconomicoPropType} props
 */
function GrupoEconomicos(props: GrupoEconomicoPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const history = useNavigate();
  const theme = useTheme();
  const api = new GrupoEconomicoAPI();

  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nmGrupoEconomico', orderType: 'ASC' });
  const [dataFiltered, setDataFiltered] = useState<GrupoEconomico[]>([]);
  const [selected, setSelected] = useState<GrupoEconomico | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Grupo Econômico');
    if (ScreensKeysEnum.GRUPO_ECONOMICO in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.GRUPO_ECONOMICO].filters, [''], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData();
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'nmGrupoEconomico', orderType: 'ASC' };

    loadData();
  }

  function handleFilterChange(data: any[]) {
    const staticFields = buildQuery(data, [''], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.GRUPO_ECONOMICO}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleFilterChange}
          filtersDefinitions={[
            {
              field: 'nmGrupoEconomico',
              label: 'Nome',
              type: TipoFiltroEnum.STRING
            }
          ]}
        />
      </section>

      <section className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          disableDelete={!selected}
          buttonEditProps={{
            disabledColor: theme.palette.grey[100],
            backgroundColor: theme.palette.primary.main
          }}
          onClickEdit={() => {
            if (!selected) return;
            history(`/cadastros/grupo-economico/editar/${selected.idGrupoEconomico}`);
          }}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<GrupoEconomico>
            columns={[
              {
                field: 'nmGrupoEconomico',
                headerName: 'Nome',
                col: 10,
                valueGetter: node => node.data.nmGrupoEconomico ?? '-'
              }
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) =>
                `${from} - ${to} de ${pageData?.totalRegistros ?? 1} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/grupo-economico/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  );

  /**
   * Carrega os dados
   *
   * @param {FilterModel[]} [filters=[]] - Filtros para os dados
   */
  function loadData(filters: FilterModel[] = []) {
    appLayoutActions.setLoading(true);

    api
      .findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as GrupoEconomico[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }
}

export default GrupoEconomicos;
