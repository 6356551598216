import { makeStyles } from '@mui/styles';

/**
 * Estilos do componente
 */
const useStyles = makeStyles({
  card: {
    width: '100%',
    height: 'calc(100vh - 126px)'
  },
  iconAdd: {
    paddingLeft: 15,
    alignItems: 'center',
    display: 'flex'
  },
  autoComplete: {
    top: '170px !important',
    left: 'calc(120px + (6 * (100% / 12))) !important'
  },
  autoCompleteUsuario: {
    left: '270px !important'
  },
  containerDataTable: {
    marginBottom: 15,
    display: 'flex',
    height: 'calc(100% - 45px)'
  }
});

export { useStyles };
