import GenericApi from 'root-resources/generic-api';
import Usuario from 'root-models/usuario';

/**
 * API do Usuário
 *
 * @author Marcos Davi <marcos.davi@bubotech.com.br>
 * @extends {RootApi}
 */
class UsuarioAPI extends GenericApi<Usuario> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/usuario' });
  }
}

export default UsuarioAPI;
