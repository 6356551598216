import { makeStyles } from '@mui/styles';

const BackgroundLogin = process.env.REACT_APP_IMAGE_BACKGROUND;

/**
 * Estilos da view de login
 **/
const useStyles = makeStyles((theme: any) => ({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  card: {
    backgroundColor: `${theme.palette.secondary.light}`,
    borderRadius: '10px !important',
    transition: ' height 220ms',
    width: 360,
    paddingTop: 100,
    marginTop: -100
  },
  grid: {
    marginTop: 30,
    marginBottom: '-20px'
  },
  esqueceuSenha: {
    display: 'flex',
    width: '100%',
    height: 60,
    marginBottom: 10,
    marginLeft: 30,
    fontSize: 14,
    color: `${theme.palette.primary.main}`,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontWeight: 300
  },
  label: {
    textDecoration: 'none',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer'
  },
  button: {
    marginBottom: 15,
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    marginLeft: '30px'
  },
  labelButton: {
    width: 126,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeight: 300,
    color: '#FFFFFF !important'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textField: {
    height: 75
  },
  textPassword: {
    overflow: 'hidden',
    transition: 'max-height 220ms',
    width: '100%',
    padding: '24px 0 0 24px'
  }
}));

export { useStyles };
