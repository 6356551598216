import React from 'react';
import { Dispatch } from 'redux';
import { useTheme } from '@mui/material';
import { MainStateType } from 'root-states';
import Tooltip from '@mui/material/Tooltip';
import { useStyles } from './toolbar.styles';
import { useNavigate } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import PerfilIcon from '@mui/icons-material/Person';
import { useSelector, useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import Notifications from '@mui/icons-material/Notifications';
import Button from '@bubotech/sumora-react-components/lib/button';
import Authentication from 'root-resources/oauth/authentication';
import AppLayoutActions from 'root-states/actions/app-layout-actions';

export type MainToolbarPropType = {};

/**
 * Representa o header/toolbar do layout principal
 *
 * @author Bruno Eduardo <bruno.soares@bubotech.com.br>
 * @param {MainToolbarPropType} props
 */
function MainToolbar(props: MainToolbarPropType): JSX.Element {
  const classes = useStyles(props);
  const history = useNavigate();
  const theme = useTheme();
  const titleToolbar = useSelector<MainStateType, string>(state => state.appLayoutReducer.titleToolbar);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  return (
    <div className={classes.container}>
      <div className={classes.containerLeftItem}>
        <Tooltip title={'Abrir/Fechar menu lateral'}>
          <div>
            <Button
              className={classes.buttonMenu}
              onClick={() => appLayoutActions.setMenuIsOpen()}
              disableElevation
            >
              <MenuIcon color='inherit' />
            </Button>
          </div>
        </Tooltip>

        <span className={classes.titleToolbar}>{titleToolbar}</span>
      </div>

      <div className={classes.containerRightItem}>
        <Tooltip title={'Notificações'}>
          <IconButton style={{ fontSize: 'inherit', color: theme.palette.primary.light }}>
            <Notifications fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Perfil'}>
          <IconButton
            onClick={() => history('/app/perfil')}
            style={{ fontSize: 'inherit', color: theme.palette.primary.light }}
          >
            <PerfilIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Tooltip title={'Sair'}>
          <IconButton
            onClick={() => {
              Authentication.logout();
              history('/login');
            }}
            style={{ fontSize: 'inherit', color: theme.palette.primary.light }}
          >
            <Logout fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default MainToolbar;
