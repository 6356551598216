import * as Yup from 'yup';
import { Dispatch } from "redux";
import { Grid } from "@mui/material";
import ConfigAPI from "../resource/api";
import { useDispatch } from "react-redux";
import Swal from "root-components/swal/swal";
import { useStyles } from "root-views/app.styles";
import { FormikHelpers, useFormik } from "formik";
import { Check, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import FirebaseConfiguracao from "../model/firebase-config";
import { DispatchAction } from "root-states/root-dispatcher";
import LoadingSwal from "root-components/loadingswal/loading-swal";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import { ButtonFAB, TextField } from "@bubotech/sumora-react-components";
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

export type FirebaseConfiguracaoPropType = {};

export interface FirebaseConfiguracaoFormikValuesType extends FirebaseConfiguracao { }

/**
 * Tela de Configuração
 * 
 * @author Carlos Bageston <carlos.bageston@bubotech.com.br>
 * @param {FirebaseConfiguracaoPropType} props
 */
export default function FirebaseConfig(props: FirebaseConfiguracaoPropType) {
    const classes = useStyles();
    const api = new ConfigAPI();
    const [edit, setEdit] = useState<boolean>(false);
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const [enableReinitialize, setEnableReinitialize] = useState(false);
    const [initialValues, setInitialValues] = useState<FirebaseConfiguracaoFormikValuesType>({
        authProviderX509CertUrl: '',
        authUri: '',
        clientEmail: '',
        clientId: '',
        clientX509CertUrl: '',
        privateKey: '',
        privateKeyId: '',
        projectId: '',
        tokenUri: '',
        type: '',
        universeDomain: ''
    });

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Configuração Firebase');
        setEnableReinitialize(true);
    });

    useEffect(() => {
        if (enableReinitialize) {
            appLayoutActions.setLoading(true);
            api
                .find()
                .then(res => {
                    if (res.data) {
                        setInitialValues(res.data as FirebaseConfiguracao)
                    }
                })
                .catch(err => {
                    Swal({
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Ok',
                        title: 'Ocorreu um erro',
                        text: 'Falha ao carregar os dados',
                        icon: 'error'
                    });
                })
                .finally(() => { appLayoutActions.setLoading(false); setEnableReinitialize(false) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableReinitialize])

    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, isValid } = useFormik<FirebaseConfiguracaoFormikValuesType>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues,
        enableReinitialize,
        validationSchema: Yup.object().shape({
            authProviderX509CertUrl: Yup.string().required('Campo obrigatório'),
            authUri: Yup.string().required('Campo obrigatório'),
            clientEmail: Yup.string().required('Campo obrigatório'),
            clientId: Yup.string().required('Campo obrigatório'),
            clientX509CertUrl: Yup.string().required('Campo obrigatório'),
            privateKey: Yup.string().required('Campo obrigatório'),
            privateKeyId: Yup.string().required('Campo obrigatório'),
            projectId: Yup.string().required('Campo obrigatório'),
            tokenUri: Yup.string().required('Campo obrigatório'),
            type: Yup.string().required('Campo obrigatório'),
            universeDomain: Yup.string().required('Campo obrigatório')
        }),
        onSubmit: handleSubmitFormik,
    });

    function handleClick(e: React.FormEvent<HTMLFormElement> | undefined) {
        if (edit) {
            handleSubmit(e);
            if (isValid) setEdit(false)
        }
        return setEdit(true)
    }
    console.log(values)

    return (
        <main style={{ height: '100%' }}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Configuração Firebase</div>
            </section>
            <Grid container className={classes.tabContent}>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'URL do Certificado Digital'}
                        name='authProviderX509CertUrl'
                        value={values?.authProviderX509CertUrl}
                        error={errors.authProviderX509CertUrl !== undefined && touched.authProviderX509CertUrl}
                        helperText={errors.authProviderX509CertUrl !== undefined && touched.authProviderX509CertUrl ? `${errors.authProviderX509CertUrl}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'URL de Autenticação'}
                        name='authUri'
                        value={values?.authUri}
                        error={errors.authUri !== undefined && touched.authUri}
                        helperText={errors.authUri !== undefined && touched.authUri ? `${errors.authUri}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Email do Cliente'}
                        name='clientEmail'
                        value={values?.clientEmail}
                        error={errors.clientEmail !== undefined && touched.clientEmail}
                        helperText={errors.clientEmail !== undefined && touched.clientEmail ? `${errors.clientEmail}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'ID do Cliente'}
                        name='clientId'
                        value={values?.clientId}
                        error={errors.clientId !== undefined && touched.clientId}
                        helperText={errors.clientId !== undefined && touched.clientId ? `${errors.clientId}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Chave X509 do Cliente '}
                        name='clientX509CertUrl'
                        value={values?.clientX509CertUrl}
                        error={errors.clientX509CertUrl !== undefined && touched.clientX509CertUrl}
                        helperText={errors.clientX509CertUrl !== undefined && touched.clientX509CertUrl ? `${errors.clientX509CertUrl}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Chave privada'}
                        name='privateKey'
                        value={values?.privateKey}
                        error={errors.privateKey !== undefined && touched.privateKey}
                        helperText={errors.privateKey !== undefined && touched.privateKey ? `${errors.privateKey}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'ID da chave privada'}
                        name='privateKeyId'
                        value={values?.privateKeyId}
                        error={errors.privateKeyId !== undefined && touched.privateKeyId}
                        helperText={errors.privateKeyId !== undefined && touched.privateKeyId ? `${errors.privateKeyId}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'ID do projeto'}
                        name='projectId'
                        value={values?.projectId}
                        error={errors.projectId !== undefined && touched.projectId}
                        helperText={errors.projectId !== undefined && touched.projectId ? `${errors.projectId}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Token Uri'}
                        name='tokenUri'
                        value={values?.tokenUri}
                        error={errors.tokenUri !== undefined && touched.tokenUri}
                        helperText={errors.tokenUri !== undefined && touched.tokenUri ? `${errors.tokenUri}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Tipo'}
                        name='type'
                        value={values?.type}
                        error={errors.type !== undefined && touched.type}
                        helperText={errors.type !== undefined && touched.type ? `${errors.type}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={3} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Domínio Universal'}
                        name='universeDomain'
                        value={values?.universeDomain}
                        error={errors.universeDomain !== undefined && touched.universeDomain}
                        helperText={errors.universeDomain !== undefined && touched.universeDomain ? `${errors.universeDomain}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>

            </Grid >
            <ButtonFAB onClick={(e: any) => handleClick(e)}>
                {!edit && isValid ? <Edit /> : <Check />}
            </ButtonFAB>
        </main>
    );

    /**
    * Manipula o evento de submit do Formik
    *
    * @param {ConfiguracaoFormikValuesType} values - Valores do submit
    * @param {FormikHelpers<ConfiguracaoFormikValuesType>} formikHelpers - Auxiliares
    */
    async function handleSubmitFormik(values: FirebaseConfiguracaoFormikValuesType, formikHelpers: FormikHelpers<FirebaseConfiguracaoFormikValuesType>): Promise<void> {
        LoadingSwal({ text: 'Carregando' });
        let error = false

        if (!values.idFirebaseConfig) {
            await api.save(values).catch(e => { error = true })
        } else {
            await api.update(values).catch(e => { error = true })
        }


        if (!error) {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: values.idFirebaseConfig ? 'Editado com sucesso' : 'Cadastrado com sucesso',
                icon: 'success',
            })
            setEdit(false)
            if (!values.idFirebaseConfig) return setEnableReinitialize(true)
        } else {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: values.idFirebaseConfig ? 'Erro ao editar' : 'Erro ao cadastrar',
                icon: 'error',
            })
        }
    }
};