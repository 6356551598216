import GenericApi from 'root-resources/generic-api';
import GrupoUsuario from 'root-models/grupo-usuario';
import { AxiosResponse } from 'axios';

/**
 * API do Grupo usuario
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @extends {RootApi}
 */
class GrupoUsuarioAPI extends GenericApi<GrupoUsuario> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/grupo' });
  }

  /**
   * Busca a lista de grupo de usuários de um determinado empresa
   * @param idEmpresa - id da empresa
   * @returns
   */
  public async findGrupoByEmpresa(idEmpresa: string): Promise<AxiosResponse<GrupoUsuario[]>> {
    return this.api.get(`empresa/${idEmpresa}`);
  }
}

export default GrupoUsuarioAPI;
