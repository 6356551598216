import { DataTable } from '@bubotech/sumora-react-components/lib';
import Usuario from 'root-models/usuario';
import { useStyles } from './grupo-usuario.styles';
import UsuarioGrupo from 'root-models/usuario-grupo';

export type UsuarioPropType = {
  usuarioSelectedList: UsuarioGrupo[];
};

/**
 * Tipo dos valores do formik
 */
export type UsuarioFormikValuesType = {
  usuario: Usuario | null;
};

/**
 * Tela de listagem e cadastros de usuarios
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {UsuarioPropType} props
 */
function Usuarios(props: UsuarioPropType): JSX.Element {
  const classes = useStyles(props);

  const { usuarioSelectedList } = props;

  return (
    <div className={classes.card}>
      <div style={{ height: 30 }} />
      <div className={classes.containerDataTable}>
        <DataTable<UsuarioGrupo>
          CardProps={{
            style: { borderRadius: 0 }
          }}
          columns={[
            {
              field: 'usuario.nmUsuario',
              headerName: 'Nome',
              col: 10
            }
          ]}
          data={usuarioSelectedList}
          onSelectRow={() => {}}
        />
      </div>
    </div>
  );
}

export default Usuarios;
