/**
 * Enum de SimNao
 *
 * @author Carlos Bageston
 */
enum SimNao {
    
    NAO,

    SIM

  }
  
  export default SimNao;