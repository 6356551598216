import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles({
  cardCadastro: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
    width: '100%'
  },
  paddingCampos: {
    padding: '25px 17px'
  },
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column'
  },
  containerDataTable: {
    display: 'flex',
    flex: 1
  },
  sectionTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  }
});

export { useStyles };
