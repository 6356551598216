import { useState } from 'react';
import { DataTable, AutoComplete } from '@bubotech/sumora-react-components/lib';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import Empresa from 'root-models/empresa';
import { useStyles } from './usuario.styles';
import { Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'root-components/swal/swal';
import EmpresaAPI from 'root-resources/api/empresa';

export type EmpresaPropType = {
  empresaSelectedList: Empresa[];
  addEmpresa: (event: Empresa) => void;
  deleteEmpresa: (event: Empresa) => void;
};

/**
 * Tipo dos valores do formik
 */
export type EmpresaFormikValuesType = {
  empresa: Empresa | null;
};

/**
 * Tela de listagem e cadastros de empresa dentro do cadastro de grupo econômico
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {EmpresaPropType} props
 */
function Empresas(props: EmpresaPropType): JSX.Element {
  const classes = useStyles(props);
  const empresaAPI = new EmpresaAPI();
  const [selected, setSelected] = useState<Empresa | undefined>();
  const [key, setKey] = useState(0);

  const { empresaSelectedList, addEmpresa, deleteEmpresa } = props;

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik<EmpresaFormikValuesType>({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: { empresa: null },
    validationSchema: Yup.object().shape({
      empresa: Yup.object().nullable().required('Campo obrigatório')
    }),
    onSubmit: () => submit()
  });

  return (
    <div className={classes.card}>
      <div style={{ padding: 30, borderBottom: '1px solid #C3D2E3' }}>
        <Grid container style={{ height: 70 }}>
          <Grid item xs={6}>
            <AutoComplete<Empresa | null>
              key={key}
              label={'Empresa'}
              variant='standard'
              name='empresa'
              error={touched.empresa !== undefined && errors.empresa !== undefined}
              helperText={touched.empresa && errors.empresa !== undefined ? `${errors.empresa}` : ''}
              value={values.empresa}
              genericApi={empresaAPI}
              searchField='nmEmpresa'
              orderField='nmEmpresa'
              staticSearchParams=',stEmpresa:1'
              getLabel={opt => opt?.nmEmpresa || ''}
              getValue={opt => opt?.idEmpresa}
              onChangeValue={e => {
                if (e) {
                  setFieldValue('empresa', e);
                }
              }}
            />
          </Grid>

          <Grid item xs={2} className={classes.iconAdd}>
            <div onClick={() => handleSubmit()} style={{ cursor: 'pointer' }}>
              <Add color='primary' />
            </div>
          </Grid>
        </Grid>
      </div>

      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete
        disableDelete={!selected}
        onClickDelete={handleDelete}
      />

      <div className={classes.containerDataTable}>
        <DataTable<Empresa>
          CardProps={{
            style: { borderRadius: 0 }
          }}
          columns={[
            {
              field: 'nmEmpresa',
              headerName: 'Empresa',
              col: 8
            },
            {
              field: 'nrCnpj',
              valueGetter: node => node.data.nrCnpj ?? '-',
              headerName: 'CNPJ',
              col: 2
            },
            {
              field: 'stEmpresa',
              valueGetter: node => {
                switch (node.data.stEmpresa) {
                  case 1:
                    return 'Ativo';
                  case 0:
                    return 'Inativo';
                  default:
                    return '';
                }
              },
              headerName: 'Situação',
              col: 2
            }
          ]}
          data={empresaSelectedList}
          onSelectRow={setSelected}
        />
      </div>
    </div>
  );

  /**
   * Adiciona uma nova empresa
   */
  function submit() {
    if (values.empresa) {
      const exist = empresaSelectedList.find(
        grupoEmpresa => grupoEmpresa?.idEmpresa === values?.empresa?.idEmpresa
      );

      if (exist) {
        Swal({
          showConfirmButton: true,
          title: 'Atenção',
          text: 'A empresa já está vinculada com esse grupo econômico',
          icon: 'warning'
        });

        return;
      }

      setFieldValue('empresa', null);
      addEmpresa(values.empresa);
      setKey(Math.random());
    }
  }

  /**
   * Deleta um empresa
   */
  function handleDelete() {
    if (!selected) {
      return;
    }

    Swal({
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      title: 'Aviso',
      text: 'Deseja remover essa empresa?',
      icon: 'warning'
    }).then((res: any) => {
      if (res.value) {
        deleteEmpresa(selected);
        setFieldValue('empresa', null);
      }
    });
  }
}

export default Empresas;
