import GenericApi from 'root-resources/generic-api';
import Empresa from 'root-models/empresa';

/**
 * API do empresa
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @extends {RootApi}
 */
class EmpresaAPI extends GenericApi<Empresa> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/empresa' });
  }
}

export default EmpresaAPI;
