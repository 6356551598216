import { makeStyles } from '@mui/styles';
import { menuColor } from 'root-utils/constants';

export const menuLateralMaxWidth = 270;

export const menuLateralMinWidth = 54;

const useStyles = makeStyles((theme: any) => ({
  menuDrawer: {
    backgroundColor: `${menuColor} !important`,
    width: menuLateralMaxWidth,
    height: '100vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRightWidth: 0
  },
  menuDrawerOpen: {
    width: menuLateralMaxWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuDrawerClose: {
    overflowX: 'hidden',
    width: menuLateralMinWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  containerLogo: {
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: menuColor
  },
  showImage: {},
  hiddenImage: {},
  logoProjeto: {
    height: 60,
    width: 150
  },
  iconProjeto: {
    height: 40,
    width: 40
  },
  containerItens: {
    paddingTop: 0,
    paddingBottom: 0,
    color: `${theme.palette.common.white} !important`,
    overflowX: 'hidden'
  },
  containerItem: {},
  itemIcon: {
    color: `${theme.palette.common.white} !important`,
    fontSize: 25,
    minWidth: '38px !important'
  },
  scrollMenu: {
    overflowY: 'auto'
  },
  scrollMenuClosed: {
    overflow: 'hidden'
  }
}));

export { useStyles };
