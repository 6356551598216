import {
  ButtonFABMenu,
  Card,
  Select,
  TextField,
  MaskedTextField
} from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { Grid } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Params } from 'react-router-dom';
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import { useStyles } from './empresa.styles';
import EmpresaAPI from 'root-resources/api/empresa';
import { v4 as uuidv4 } from 'uuid';
import { MaskTypeEnum } from '@bubotech/sumora-react-components/lib/maskedtextfield';
import CNPJValidator from 'root-validators/cnpj-validator';

/**
 * Tipo dos valores do formik
 */
export type EditarEmpresaFormikValuesType = {
  idEmpresa?: string;
  nmEmpresa?: string;
  stEmpresa?: SituacaoEnum;
  nrCnpj?: string;
};

export type EditarEmpresaPropType = {};

/**
 * View de edição de empresa
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {EditarEmpresaPropType} props
 */
function EditarEmpresa(props: EditarEmpresaPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const history = useNavigate();
  const api = new EmpresaAPI();
  const { id }: Readonly<Params<'id'>> = useParams();
  const classes = useStyles(props);

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<EditarEmpresaFormikValuesType>({
    nmEmpresa: '',
    nrCnpj: '',
    stEmpresa: SituacaoEnum.ATIVO
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<EditarEmpresaFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        nmEmpresa: Yup.string().required('Campo obrigatório'),
        nrCnpj: Yup.string()
          .nullable()
          .test('', 'CNPJ/CPF inválido', function (value) {
            if (value !== undefined) {
              return CNPJValidator(value);
            }
            return true;
          })
          .required('Campo obrigatório')
      }),
      onSubmit: handleSubmitFormik
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Empresa');

    if (id) {
      setEnableReinitialize(true);
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api
      .findById(id)
      .then(res => {
        setInitialValues(res.data);
        setEnableReinitialize(false);
        appLayoutActions.setLoading(false);
      })
      .catch(() => {
        appLayoutActions.setLoading(false);

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <Card
      titleDivProps={{
        className: classes.cardCadastro
      }}
      contentContainerDivProps={{
        className: classes.paddingCampos
      }}
      title={id ? 'Editar empresa' : 'Cadastrar empresa'}
    >
      <Grid container style={{ height: 70 }}>
        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextField
            variant='standard'
            label={'Empresa'}
            name='nmEmpresa'
            value={values.nmEmpresa}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nmEmpresa && errors.nmEmpresa !== undefined}
            helperText={touched.nmEmpresa && errors.nmEmpresa !== undefined ? `${errors.nmEmpresa}` : ''}
          />
        </Grid>

        <Grid item xs={3} style={{ paddingRight: 15 }}>
          <MaskedTextField
            label={'CNPJ'}
            typeMask={MaskTypeEnum.CNPJ}
            value={values.nrCnpj}
            onChangeValue={(value: string) => setFieldValue('nrCnpj', value)}
            onBlur={handleBlur}
            error={touched.nrCnpj && errors.nrCnpj !== undefined}
            helperText={touched.nrCnpj && errors.nrCnpj !== undefined ? `${errors.nrCnpj}` : ''}
          />
        </Grid>

        <Grid item xs={2}>
          <Select<string, number>
            label={'Situação'}
            value={typeof values.stEmpresa !== 'string' ? values.stEmpresa : null}
            onChangeValue={(value, e) => {
              setFieldValue('stEmpresa', e.target.value);
            }}
            onBlur={handleBlur}
            placeholder='Selecione...'
            options={['Inativo', 'Ativo']}
            getOptionLabel={opt => opt}
            getOptionValue={(opt, i) => i}
            error={touched.stEmpresa && errors.stEmpresa !== undefined}
            helperText={touched.stEmpresa && errors.stEmpresa !== undefined ? `${errors.stEmpresa}` : ''}
          />
        </Grid>
      </Grid>

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{ onClick: (e: any) => handleSubmit(e), iconProps: { color: 'inherit' } }}
        secondaryAction={{
          onClick: () => history('/cadastros/empresa'),
          iconProps: { color: 'inherit' }
        }}
      />
    </Card>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarEmpresaFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarEmpresaFormikValuesType>} formikHelpers - Auxiliares
   */
  async function handleSubmitFormik(
    values: EditarEmpresaFormikValuesType,
    formikHelpers: FormikHelpers<EditarEmpresaFormikValuesType>
  ) {
    LoadingSwal({ text: 'Carregando' });

    let error = false;

    if (!id) {
      values.idEmpresa = uuidv4();
      await api.save(values).catch(e => {
        error = true;
      });
    } else {
      values.idEmpresa = id;
      await api.update(values).catch(e => {
        error = true;
      });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success'
      });

      history('/cadastros/empresa');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error'
      });
    }
  }
}

export default EditarEmpresa;
