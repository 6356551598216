import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../app.styles';
import { FormikErrors, FormikTouched } from 'formik';
import { EditarUsuarioFormikValuesType } from './editar-usuario';
import { TextField, Select, MaskedTextField } from '@bubotech/sumora-react-components/lib';
import { MaskTypeEnum } from '@bubotech/sumora-react-components/lib/maskedtextfield';

export type EditarDadosUsuarioPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarUsuarioFormikValuesType;
  errors: FormikErrors<EditarUsuarioFormikValuesType>;
  touched: FormikTouched<EditarUsuarioFormikValuesType>;
};

/**
 * View de edição de dados de um usuário
 *
 * @author Marcos Davi <marcos.davi@bubotech.com.br>
 * @param {EditarDadosUsuarioPropType} props
 */
function EditarDadosUsuario(props: EditarDadosUsuarioPropType): JSX.Element {
  const classes = useStyles();
  const { setFieldValue, handleBlur, values, errors, touched } = props;

  return (
    <Grid container className={classes.tabContent}>
      <Grid item xs={4} className={classes.gridCell}>
        <TextField
          label={'Nome'}
          name='nmUsuario'
          inputProps={{ maxLength: 40 }}
          value={values.nmUsuario}
          error={touched.nmUsuario && errors.nmUsuario !== undefined}
          helperText={touched.nmUsuario && errors.nmUsuario !== undefined ? `${errors.nmUsuario}` : ''}
          onBlur={handleBlur}
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={3} style={{ paddingRight: 15 }}>
        <MaskedTextField
          label={'Telefone'}
          typeMask={MaskTypeEnum.TELEPHONE}
          value={values.nrTelefone}
          onChangeValue={(value: string) => setFieldValue('nrTelefone', value)}
          onBlur={handleBlur}
          error={touched.nrTelefone && errors.nrTelefone !== undefined}
          helperText={
            touched.nrTelefone && errors.nrTelefone !== undefined ? `${errors.nrTelefone}` : ''
          }
        />
      </Grid>

      <Grid item xs={3} className={classes.gridCell}>
        <TextField
          label={'Email'}
          name='dsEmail'
          inputProps={{ maxLength: 40 }}
          value={values?.dsEmail}
          error={errors.dsEmail !== undefined && touched.dsEmail}
          helperText={errors.dsEmail !== undefined && touched.dsEmail ? `${errors.dsEmail}` : ''}
          onBlur={e => {
            handleBlur(e);
          }}
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
      </Grid>

      <Grid item xs={2}>
        <Select<string, number>
          label={'Situação'}
          value={typeof values.stUsuario !== 'string' ? values.stUsuario : null}
          onChangeValue={(value, e) => {
            setFieldValue('stUsuario', e.target.value);
          }}
          onBlur={handleBlur}
          placeholder='Selecione...'
          options={['Inativo', 'Ativo']}
          getOptionLabel={opt => opt}
          getOptionValue={(opt, i) => i}
          error={touched.stUsuario && errors.stUsuario !== undefined}
          helperText={touched.stUsuario && errors.stUsuario !== undefined ? `${errors.stUsuario}` : ''}
        />
      </Grid>
    </Grid>
  );
}

export default EditarDadosUsuario;
