import GenericApi from 'root-resources/generic-api';
import FirebaseConfig from '../model/firebase-config';
import { AxiosResponse } from 'axios';
/**
 * API de FirebaseConfig
 *
 * @author Carlos Bageston <carlos.bageston@bubotech.com.br>
 * @extends {RootApi}
 */
class FirebaseConfigAPI extends GenericApi<FirebaseConfig> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao FirebaseConfig
   */
  public constructor() {
    super({ baseEndpoint: '/firebase-config' });
  }
  
  public async find(): Promise<AxiosResponse<FirebaseConfig>> {
    return this.api.get('/');
  }
}

export default FirebaseConfigAPI;
