import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../app.styles';
import { FormikErrors, FormikTouched } from 'formik';
import { EditarGrupoUsuarioFormikValuesType } from './editar-grupo-usuarios';
import { TextField, Select, AutoComplete } from '@bubotech/sumora-react-components/lib';
import Empresa from 'root-models/empresa';
import EmpresaAPI from 'root-resources/api/empresa';

export type EditarDadosUsuarioPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarGrupoUsuarioFormikValuesType;
  errors: FormikErrors<EditarGrupoUsuarioFormikValuesType>;
  touched: FormikTouched<EditarGrupoUsuarioFormikValuesType>;
  key?: number;
};

/**
 * View de edição de dados de um grupode usuários
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {EditarDadosUsuarioPropType} props
 */
function EditarDadosUsuario(props: EditarDadosUsuarioPropType): JSX.Element {
  const classes = useStyles();
  const { setFieldValue, handleBlur, values, errors, touched, key } = props;
  const empresaAPI = new EmpresaAPI();

  return (
    <Grid container className={classes.tabContent}>
      <Grid item xs={4} style={{ paddingRight: 15 }}>
        <AutoComplete<Empresa | null>
          label={'Empresa'}
          key={key}
          variant='standard'
          name='empresa'
          error={touched.empresa !== undefined && errors.empresa !== undefined}
          helperText={touched.empresa && errors.empresa !== undefined ? `${errors.empresa}` : ''}
          value={values.empresa}
          genericApi={empresaAPI}
          searchField='nmEmpresa'
          orderField='nmEmpresa'
          staticSearchParams=',stEmpresa:1'
          getLabel={opt => opt?.nmEmpresa || ''}
          getValue={opt => opt?.idEmpresa}
          onChangeValue={e => {
            if (e) {
              setFieldValue('empresa', e);
            }
          }}
        />
      </Grid>

      <Grid item xs={4} className={classes.gridCell}>
        <TextField
          label={'Nome'}
          name='nmGrupo'
          inputProps={{ maxLength: 40 }}
          value={values?.nmGrupo}
          error={errors.nmGrupo !== undefined && touched.nmGrupo}
          helperText={errors.nmGrupo !== undefined && touched.nmGrupo ? `${errors.nmGrupo}` : ''}
          onBlur={e => {
            handleBlur(e);
          }}
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
      </Grid>

      <Grid item xs={2}>
        <Select<string, number>
          label={'Situação'}
          value={typeof values.stGrupo !== 'string' ? values.stGrupo : null}
          onChangeValue={(value, e) => {
            setFieldValue('stGrupo', e.target.value);
          }}
          onBlur={handleBlur}
          placeholder='Selecione...'
          options={['Inativo', 'Ativo']}
          getOptionLabel={opt => opt}
          getOptionValue={(opt, i) => i}
          error={touched.stGrupo && errors.stGrupo !== undefined}
          helperText={touched.stGrupo && errors.stGrupo !== undefined ? `${errors.stGrupo}` : ''}
        />
      </Grid>

      <Grid item xs={12} className={classes.gridCell}>
        <TextField
          label='Descrição'
          name='dsGrupo'
          value={values.dsGrupo}
          onChange={e => setFieldValue('dsGrupo', e.target.value)}
          error={touched.dsGrupo && errors.dsGrupo !== undefined}
          helperText={touched.dsGrupo && errors.dsGrupo ? `${errors.dsGrupo}` : ''}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
}

export default EditarDadosUsuario;
