const ptBR = {
  login: {
    campoObrigatorio: 'Campo obrigatório',
    emailInvalido: 'Email inválido',
    minimoSenha: 'A senha deve ter no mínimo 6 caracteres',
    senha: 'Senha *',
    retornarLogin: 'Retornar Login',
    esqueceuSenha: 'Esqueceu a senha?',
    enviar: 'Enviar',
    login: 'Entrar',
    falhaRealizarLogin: 'Falha ao realizar login',
    usuarioSenhaIncorreto: 'Usuário ou senha incorretos',
    falhaUsuarioInativo: 'Usuário está inativo',
    falhaClienteInativo: 'Cliente está inativo',
    falhaLoginMessage: 'Ocorreu uma falha ao realizar o login',
    emailEnviado: 'Foi enviado um E-mail com um link para você trocar sua senha',
    emailEnviadoMessage: 'E-mail enviado para ',
    usuarioNaoEncontrado: 'Usuário não encontrado',
    falhaRecuperarSenha: 'Falha ao recuperar senha'
  }
};

export default ptBR;
