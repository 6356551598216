import GenericApi from 'root-resources/generic-api';
import GrupoEconomico from 'root-models/grupo-economico';

/**
 * API do Grupo econômico
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @extends {RootApi}
 */
class GrupoEconomicoAPI extends GenericApi<GrupoEconomico> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/grupo-economico' });
  }
}

export default GrupoEconomicoAPI;
