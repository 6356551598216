import React from 'react';
import Card from '@bubotech/sumora-react-components/lib/card';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { Dispatch } from 'redux';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

export type DashboardPropType = {};

/**
 * Representa a tela do Dashboard
 *
 * @author Bruno Eduardo <bruno.soares@bubotech.com.br>
 * @param {DashboardPropType} props
 */
function Dashboard(props: DashboardPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Dashboard');
  });

  return <Card title='Dashboard title'>Dashboard content</Card>;
}

export default Dashboard;
