/**
 * Enum de Permissão Bloqueio
 *
 * @author Carlos Bageston
 */
enum PermissaoBloqueio {
    
    PERMISSAO,

    BLOQUEIO

  }
  
  export default PermissaoBloqueio;