import { makeStyles } from '@mui/styles'
import {
  menuLateralMaxWidth,
  menuLateralMinWidth,
} from '../components/menulateral/menu.styles'
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles'

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles((theme: any) => ({
  main: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
  },
  containerToolbarAndRoutes: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `calc(100vw - ${menuLateralMinWidth}px)`,
    minWidth: `calc(100vw - ${menuLateralMaxWidth}px)`,
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${toolbarHeight}px)`,
  },
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  containerDataTable: {
    display: 'flex',
    flex: 1,
  },
  titleContainer: {
    maxHeight: 44,
    padding: '12px 25px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 18,
    color: '#000000',
    fontWeight: 400,
    width: '100%',
  },
  content: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: 25,
    flex: 1,
  },
  tabContent: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: '25px 17px',
    flex: 1,
    overflow: 'hidden',
  },
  switchContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  sectionTable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  },
  gridCell: {
    paddingRight: 25,
    height: 76
  },
  chooseImage: {
    padding: '18px 5px',
    borderRadius: `16px !important`,
    backgroundColor: `${theme.palette.primary.dark} !important`
  },
  fileLabel: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontFamily: 'Roboto',
    margin: '0 0 0 8px',
    padding: 0,
    alignSelf: 'center'
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
    padding: '25px 0 12px',
    color: `${theme.palette.primary.dark}`,
  },
  icone: {
    height: 21,
    width: 21,
    filter: 'invert(40%) sepia(12%) saturate(733%) hue-rotate(183deg) brightness(91%) contrast(89%)'
  },
  iconeSelecionado: {
    filter: 'invert(17%) sepia(32%) saturate(2955%) hue-rotate(205deg) brightness(95%) contrast(92%)'
  },
  totalPedido: {
    foontSize: '18px',
    fontWeight: 600,
    color: '#213670'
  }
}))

export { useStyles }
