import { useState } from 'react';
import { DataTable, AutoComplete } from '@bubotech/sumora-react-components/lib';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useStyles } from './usuario.styles';
import { Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'root-components/swal/swal';
import GrupoUsuario from 'root-models/grupo-usuario';
import GrupoUsuarioAPI from 'root-resources/api/grupo-usuario';
import Empresa from 'root-models/empresa';

export type UsuarioPropType = {
  grupoUsuarioSelectedList: GrupoUsuario[];
  addGrupoUsuario: (event: GrupoUsuario) => void;
  deletegrupoUsuario: (event: GrupoUsuario) => void;
  empresaList: Empresa[];
};

/**
 * Tipo dos valores do formik
 */
export type UsuarioFormikValuesType = {
  grupoUsuario: GrupoUsuario | null;
  empresa: Empresa | null;
};

/**
 * Tela de listagem e cadastros de grupo de usuarios dentro do cadastro de usuário
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {UsuarioPropType} props
 */
function GrupoUsuarios(props: UsuarioPropType): JSX.Element {
  const classes = useStyles(props);
  const grupoUsuarioAPI = new GrupoUsuarioAPI();
  const [key, setKey] = useState(0);

  const [selected, setSelected] = useState<GrupoUsuario | undefined>();
  const [grupoUsuarioList, setGrupoUsuarioList] = useState<GrupoUsuario[]>();

  const { grupoUsuarioSelectedList, addGrupoUsuario, deletegrupoUsuario, empresaList } = props;

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik<UsuarioFormikValuesType>({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: { grupoUsuario: null, empresa: null },
    validationSchema: Yup.object().shape({
      grupoUsuario: Yup.object().nullable().required('Campo obrigatório')
    }),
    onSubmit: () => submit()
  });

  return (
    <div className={classes.card}>
      <div style={{ padding: 30, borderBottom: '1px solid #C3D2E3' }}>
        <Grid container style={{ height: 70 }}>
          <Grid item xs={4} style={{ paddingRight: 15 }}>
            <AutoComplete<Empresa | null>
              key={key}
              suggestions={empresaList}
              getLabel='nmEmpresa'
              getValue='idEmpresa'
              onChangeValue={findGrupoByEmpresa}
              onChangeInput={() => {}}
              onBlur={() => {}}
              value={values.empresa}
              label={'Empresa'}
              error={touched.empresa && errors.empresa !== undefined}
              errorText={touched.empresa && errors.empresa !== undefined ? `${errors.empresa}` : ''}
            />
          </Grid>
          <Grid item xs={4} style={{ paddingRight: 15 }}>
            <AutoComplete<GrupoUsuario | null>
              key={key}
              suggestions={grupoUsuarioList}
              getLabel='nmGrupo'
              getValue='idGrupo'
              onChangeValue={value => setFieldValue('grupoUsuario', value)}
              onChangeInput={value => setFieldValue('grupoUsuario', value)}
              onBlur={value => setFieldValue('grupoUsuario', value)}
              value={values.grupoUsuario}
              label={'Grupo de usuários'}
              error={touched.grupoUsuario && errors.grupoUsuario !== undefined}
              errorText={
                touched.grupoUsuario && errors.grupoUsuario !== undefined ? `${errors.grupoUsuario}` : ''
              }
            />
          </Grid>
          <Grid item xs={2} className={classes.iconAdd}>
            <div onClick={() => handleSubmit()} style={{ cursor: 'pointer' }}>
              <Add color='primary' />
            </div>
          </Grid>
        </Grid>
      </div>

      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete
        disableDelete={!selected}
        onClickDelete={handleDelete}
      />

      <div className={classes.containerDataTable}>
        <DataTable<GrupoUsuario>
          CardProps={{
            style: { borderRadius: 0 }
          }}
          columns={[
            {
              field: 'nmGrupo',
              headerName: 'Grupo de usuário',
              col: 10
            }
          ]}
          data={grupoUsuarioSelectedList}
          onSelectRow={setSelected}
        />
      </div>
    </div>
  );

  function findGrupoByEmpresa(value: Empresa | null) {
    console.log(value);
    setFieldValue('empresa', value);

    if (value) {
      grupoUsuarioAPI
        .findGrupoByEmpresa(value.idEmpresa ?? '')
        .then(res => {
          setGrupoUsuarioList(res.data);
          setFieldValue('grupoUsuario', null);
          setKey(Math.random());
        })
        .catch(() => setGrupoUsuarioList([]));
    }
  }

  /**
   * Adiciona um novo grupo de usuarios
   */
  function submit() {
    if (values.grupoUsuario) {
      const exist = grupoUsuarioSelectedList.find(
        grupo => grupo?.idGrupo === values?.grupoUsuario?.idGrupo
      );

      if (exist) {
        Swal({
          showConfirmButton: true,
          title: 'Atenção',
          text: 'O grupo de usuários já está vinculada com esse usuário',
          icon: 'warning'
        });

        return;
      }

      setFieldValue('grupoUsuario', null);
      setFieldValue('empresa', null);
      addGrupoUsuario(values.grupoUsuario);
      setGrupoUsuarioList([]);
      setKey(Math.random());
    }
  }

  /**
   * Deleta um usuario
   */
  function handleDelete() {
    if (!selected) {
      return;
    }

    Swal({
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      title: 'Aviso',
      text: 'Deseja remover esse grupo de usuário?',
      icon: 'warning'
    }).then((res: any) => {
      if (res.value) {
        deletegrupoUsuario(selected);
        setFieldValue('usuario', null);
      }
    });
  }
}

export default GrupoUsuarios;
