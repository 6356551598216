import React from 'react';
import clsx from 'clsx';
import List from '@mui/material/List';
import Grow from '@mui/material/Grow';
import { Link } from 'react-router-dom';
import { useStyles } from './menu.styles';
import Drawer from '@mui/material/Drawer';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import MenuLateralItem from './menu-lateral-item';
import itensMenuLateral from './menu-itens-definitions';
import { iconeCliente, logoCliente } from 'root-utils/constants';

const logoProjeto = logoCliente;
const iconProjeto = iconeCliente;

/**
 * Representa o menu lateral do layout principal
 *
 * @author Marcos Davi <marcos.davi@bubotech.com.br>
 * @returns {JSX.Element}
 */
function MenuLateral(): JSX.Element {
  const classes = useStyles();
  const menuOpen = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.menuOpen);
  const drawerClasses = clsx(classes.menuDrawer, {
    [classes.menuDrawerOpen]: menuOpen,
    [classes.menuDrawerClose]: !menuOpen
  });

  return (
    <Drawer variant='permanent' className={drawerClasses} classes={{ paper: drawerClasses }}>
      <Link to='/dashboard' className={classes.containerLogo}>
        <Grow in={menuOpen} timeout={800}>
          {menuOpen ? <img src={logoProjeto} className={classes.logoProjeto} alt='logo' /> : <div />}
        </Grow>

        <Grow in={!menuOpen} timeout={800}>
          {!menuOpen ? <img src={iconProjeto} className={classes.iconProjeto} alt='icone' /> : <div />}
        </Grow>
      </Link>

      <List
        className={clsx(classes.containerItens, {
          [classes.scrollMenu]: menuOpen,
          [classes.scrollMenuClosed]: !menuOpen
        })}
      >
        {itensMenuLateral.map((item, i) => (
          <MenuLateralItem item={item} level={0} key={i} />
        ))}
      </List>
    </Drawer>
  );
}

export default MenuLateral;
