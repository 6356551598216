import React from 'react';
import { useStyles } from './cabecalho.styles';
import Card from '@bubotech/sumora-react-components/lib/card';

const logoProjeto = process.env.REACT_APP_LOGO_LOGIN;

export type CabecalhoPropType = {};

/**
 * Componente para um cabeçalho com logo
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @param {CabecalhoPropType} props
 */
function Cabecalho(props: CabecalhoPropType): JSX.Element {
  const classes = useStyles(props);

  return (
    <Card className={classes.card} contentContainerDivProps={{ style: { padding: 0 } }} elevation={10}>
      <div className={classes.container}>
        <img src={logoProjeto} style={{ height: '100%' }} alt='logo-projeto' />
      </div>
    </Card>
  );
}

export default Cabecalho;
