import * as Yup from 'yup';
import { Dispatch } from "redux";
import { Grid } from "@mui/material";
import ConfigAPI from "../resource/api";
import { useDispatch } from "react-redux";
import Swal from "root-components/swal/swal";
import Busca from "root-models/parametrosbusca";
import { useStyles } from "root-views/app.styles";
import { FormikHelpers, useFormik } from "formik";
import Configuracaos from "../model/configuracao";
import { Check, Edit } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import SimNao from "root-enumerations/sim-nao-enum";
import { DispatchAction } from "root-states/root-dispatcher";
import IntegracaoSms from "root-enumerations/integracao-sms-enum";
import LoadingSwal from "root-components/loadingswal/loading-swal";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import { ButtonFAB, Select, TextField } from "@bubotech/sumora-react-components";
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import PermissaoBloqueio from 'root-enumerations/permissao-bloqueio-enum';

export type ConfiguracaoPropType = {};

export interface ConfiguracaoFormikValuesType extends Configuracaos { }

/**
 * Tela de Configuração
 * 
 * @author Carlos Bageston <carlos.bageston@bubotech.com.br>
 * @param {ConfiguracaoPropType} props
 */
export default function Configuracao(props: ConfiguracaoPropType) {
    const classes = useStyles();
    const api = new ConfigAPI();
    const [edit, setEdit] = useState<boolean>(false);
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const params = useRef<Busca>({ page: 1, orderField: 'nmAdministrador', orderType: 'ASC' });
    const [enableReinitialize, setEnableReinitialize] = useState(false);
    const [initialValues, setInitialValues] = useState<ConfiguracaoFormikValuesType>({
        dsAdministradorEmail: '',
        dsUrl: '',
        nmAdministrador: '',
        nrValidadeToken: '',
        stGrupoEconomico: SimNao.NAO,
        stMultitenant: SimNao.NAO,
        tpPermissao: SimNao.NAO,
        tpResourceCliente: SimNao.NAO,
        tpUsuarioCliente: SimNao.NAO,
        stReplicacao: SimNao.NAO,
        tpIntegracaoSms: IntegracaoSms.NENHUM,
        tpInterfaceWeb: SimNao.NAO
    });

    useComponentDidMount(() => {
        appLayoutActions.setTitleToolbar('Configuração');
        setEnableReinitialize(true);
    });

    useEffect(() => {
        if (enableReinitialize) {
            appLayoutActions.setLoading(true);
            api
                .find()
                .then(res => {
                    setInitialValues(res.data as Configuracaos)
                })
                .catch(err => {
                    console.log(err)
                    Swal({
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Ok',
                        title: 'Ocorreu um erro',
                        text: 'Falha ao carregar os dados',
                        icon: 'error'
                    });
                })
                .finally(() => { appLayoutActions.setLoading(false); setEnableReinitialize(false) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableReinitialize])

    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik<ConfiguracaoFormikValuesType>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues,
        enableReinitialize,
        validationSchema: Yup.object().shape({
            dsAdministradorEmail: Yup.string().required('Campo obrigatório'),
            dsUrl: Yup.string().required('Campo obrigatório'),
            nmAdministrador: Yup.string().required('Campo obrigatório'),
            nrValidadeToken: Yup.string().required('Campo obrigatório'),
            stGrupoEconomico: Yup.boolean().default(true).required('Campo obrigatório'),
            stMultitenant: Yup.boolean().default(true).required('Campo obrigatório'),
            tpPermissao: Yup.boolean().default(true).required('Campo obrigatório'),
            tpResourceCliente: Yup.boolean().default(true).required('Campo obrigatório'),
            tpUsuarioCliente: Yup.boolean().default(true).required('Campo obrigatório'),
            stReplicacao: Yup.boolean().default(true).required('Campo obrigatório'),
            tpIntegracaoSms: Yup.number().required('Campo obrigatório'),
            tpInterfaceWeb: Yup.boolean().default(true).required('Campo obrigatório'),
        }),
        onSubmit: handleSubmitFormik,
    });

    function handleClick(e: React.FormEvent<HTMLFormElement> | undefined) {
        return !edit ? setEdit(true) : (handleSubmit(e), setEdit(false));
    }

    return (
        <main style={{ height: '100%' }}>
            <section id='titulo-pagina'>
                <div className={classes.titleContainer}>Configuração</div>
            </section>
            <Grid container className={classes.tabContent}>
                <Grid item xs={4} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Nome Administrador'}
                        name='nmAdministrador'
                        inputProps={{ maxLength: 100 }}
                        value={values?.nmAdministrador}
                        error={errors.nmAdministrador !== undefined && touched.nmAdministrador}
                        helperText={errors.nmAdministrador !== undefined && touched.nmAdministrador ? `${errors.nmAdministrador}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={8} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Descrição'}
                        name='dsAdministradorEmail'
                        inputProps={{ maxLength: 100 }}
                        value={values?.dsAdministradorEmail}
                        error={errors.dsAdministradorEmail !== undefined && touched.dsAdministradorEmail}
                        helperText={errors.dsAdministradorEmail !== undefined && touched.dsAdministradorEmail ? `${errors.dsAdministradorEmail}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={4} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'URL'}
                        name='dsUrl'
                        inputProps={{ maxLength: 200 }}
                        value={values?.dsUrl}
                        error={errors.dsUrl !== undefined && touched.dsUrl}
                        helperText={errors.dsUrl !== undefined && touched.dsUrl ? `${errors.dsUrl}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid item xs={2} className={classes.gridCell}>
                    <TextField
                        disabled={!edit}
                        label={'Validade do token'}
                        name='nrValidadeToken'
                        inputProps={{ maxLength: 10 }}
                        value={values?.nrValidadeToken}
                        error={errors.nrValidadeToken !== undefined && touched.nrValidadeToken}
                        helperText={errors.nrValidadeToken !== undefined && touched.nrValidadeToken ? `${errors.nrValidadeToken}` : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue(e.target.name, e.target.value.replace(/[^0-9]/g, ''))}
                    />
                </Grid>
                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Grupo Econômico'}
                        variant='standard'
                        name='stGrupoEconomico'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.stGrupoEconomico && errors.stGrupoEconomico !== undefined ? `${errors.stGrupoEconomico}` : ''}
                        error={touched.stGrupoEconomico && errors.stGrupoEconomico !== undefined}
                        errorText={touched.stGrupoEconomico && errors.stGrupoEconomico !== undefined ? `${errors.stGrupoEconomico}` : ''}
                        value={values.stGrupoEconomico}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('stGrupoEconomico', e.value)}
                    />
                </Grid>

                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Multitenant'}
                        variant='standard'
                        name='stMultitenant'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.stMultitenant && errors.stMultitenant !== undefined ? `${errors.stMultitenant}` : ''}
                        error={touched.stMultitenant && errors.stMultitenant !== undefined}
                        errorText={touched.stMultitenant && errors.stMultitenant !== undefined ? `${errors.stMultitenant}` : ''}
                        value={values.stMultitenant}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('stMultitenant', e.value)}
                    />
                </Grid>
                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Cliente'}
                        variant='standard'
                        name='tpResourceCliente'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.tpResourceCliente && errors.tpResourceCliente !== undefined ? `${errors.tpResourceCliente}` : ''}
                        error={touched.tpResourceCliente && errors.tpResourceCliente !== undefined}
                        errorText={touched.tpResourceCliente && errors.tpResourceCliente !== undefined ? `${errors.tpResourceCliente}` : ''}
                        value={values.tpResourceCliente}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('tpResourceCliente', e.value)}
                    />
                </Grid>
                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Usuario'}
                        variant='standard'
                        name='tpUsuarioCliente'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.tpUsuarioCliente && errors.tpUsuarioCliente !== undefined ? `${errors.tpUsuarioCliente}` : ''}
                        error={touched.tpUsuarioCliente && errors.tpUsuarioCliente !== undefined}
                        errorText={touched.tpUsuarioCliente && errors.tpUsuarioCliente !== undefined ? `${errors.tpUsuarioCliente}` : ''}
                        value={values.tpUsuarioCliente}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('tpUsuarioCliente', e.value)}
                    />
                </Grid>

                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Replicação'}
                        variant='standard'
                        name='stReplicacao'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.stReplicacao && errors.stReplicacao !== undefined ? `${errors.stReplicacao}` : ''}
                        error={touched.stReplicacao && errors.stReplicacao !== undefined}
                        errorText={touched.stReplicacao && errors.stReplicacao !== undefined ? `${errors.stReplicacao}` : ''}
                        value={values.stReplicacao}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('stReplicacao', e.value)}
                    />
                </Grid>

                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Interface Web'}
                        variant='standard'
                        name='tpInterfaceWeb'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.tpInterfaceWeb && errors.tpInterfaceWeb !== undefined ? `${errors.tpInterfaceWeb}` : ''}
                        error={touched.tpInterfaceWeb && errors.tpInterfaceWeb !== undefined}
                        errorText={touched.tpInterfaceWeb && errors.tpInterfaceWeb !== undefined ? `${errors.tpInterfaceWeb}` : ''}
                        value={values.tpInterfaceWeb}
                        options={[
                            { label: 'Não', value: SimNao.NAO },
                            { label: 'Sim', value: SimNao.SIM }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('tpInterfaceWeb', e.value)}
                    />
                </Grid>

                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Integração SMS'}
                        variant='standard'
                        name='tpIntegracaoSms'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.tpIntegracaoSms && errors.tpIntegracaoSms !== undefined ? `${errors.tpIntegracaoSms}` : ''}
                        error={touched.tpIntegracaoSms && errors.tpIntegracaoSms !== undefined}
                        errorText={touched.tpIntegracaoSms && errors.tpIntegracaoSms !== undefined ? `${errors.tpIntegracaoSms}` : ''}
                        value={values.tpIntegracaoSms}
                        options={[
                            { label: 'Nenhum', value: IntegracaoSms.NENHUM },
                            { label: 'Firebase', value: IntegracaoSms.FIREBASE },
                            { label: 'Pinpoint', value: IntegracaoSms.PINPOINT },
                            { label: 'Twilio', value: IntegracaoSms.TWILIO },
                            { label: 'Zenvia', value: IntegracaoSms.ZENVIA },
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('tpIntegracaoSms', e.value)}
                    />
                </Grid>

                <Grid item xs={2} className={classes.gridCell}>
                    <Select<any>
                        disabled={!edit}
                        label={'Permissão'}
                        variant='standard'
                        name='tpPermissao'
                        placeholder='Selecione...'
                        onBlur={handleBlur}
                        helperText={touched.tpPermissao && errors.tpPermissao !== undefined ? `${errors.tpPermissao}` : ''}
                        error={touched.tpPermissao && errors.tpPermissao !== undefined}
                        errorText={touched.tpPermissao && errors.tpPermissao !== undefined ? `${errors.tpPermissao}` : ''}
                        value={values.tpPermissao}
                        options={[
                            { label: 'Permissão', value: PermissaoBloqueio.PERMISSAO },
                            { label: 'Bloqueio', value: PermissaoBloqueio.BLOQUEIO }
                        ]}
                        getOptionLabel={opt => opt.label}
                        getOptionValue={(opt) => opt.value}
                        onChangeValue={(e) => setFieldValue('tpPermissao', e.value)}
                    />
                </Grid>

            </Grid >
            <ButtonFAB onClick={(e: any) => handleClick(e)}>
                {!edit ? <Edit /> : <Check />}
            </ButtonFAB>
        </main>
    );

    /**
    * Manipula o evento de submit do Formik
    *
    * @param {ConfiguracaoFormikValuesType} values - Valores do submit
    * @param {FormikHelpers<ConfiguracaoFormikValuesType>} formikHelpers - Auxiliares
    */
    async function handleSubmitFormik(values: ConfiguracaoFormikValuesType, formikHelpers: FormikHelpers<ConfiguracaoFormikValuesType>): Promise<void> {
        LoadingSwal({ text: 'Carregando' });
        let error = false

        if (!values.idConfig) {
            await api.save(values).catch(e => { error = true })
        } else {
            await api.update(values).catch(e => { error = true })
        }


        if (!error) {
            Swal({
                showConfirmButton: true,
                title: 'Sucesso',
                text: values.idConfig ? 'Editado com sucesso' : 'Cadastrado com sucesso',
                icon: 'success',
            })
            setEdit(false)
            if (!values.idConfig) return setEnableReinitialize(true)
        } else {
            Swal({
                showConfirmButton: true,
                title: 'Erro',
                text: values.idConfig ? 'Erro ao editar' : 'Erro ao cadastrar',
                icon: 'error',
            })
        }
    }
};