import GenericApi from 'root-resources/generic-api';
import Configuracao from '../model/configuracao';
import { AxiosResponse } from 'axios';

/**
 * API de Configuração
 *
 * @author Carlos Bageston <carlos.bageston@bubotech.com.br>
 * @extends {RootApi}
 */
class ConfigAPI extends GenericApi<Configuracao> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Configuração
   */
  public constructor() {
    super({ baseEndpoint: '/config' });
  }

  public async find(): Promise<AxiosResponse<Configuracao>> {
    return this.api.get('/');
  }
}

export default ConfigAPI;
