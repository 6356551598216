import { useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import Swal from 'root-components/swal/swal';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './empresa.styles';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import { DispatchAction } from 'root-states/root-dispatcher';
import CardFilters from 'root-components/cardfilters/card-filters';
import DataTable from '@bubotech/sumora-react-components/lib/datatable';
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import GroupButtonGrid from '@bubotech/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import EmpresaAPI from 'root-resources/api/empresa';
import Resposta from 'root-models/respostaAPI';
import Busca from 'root-models/parametrosbusca';
import Empresa from 'root-models/empresa';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { buildQuery } from 'root-utils/build-query';
import { TableOrder } from 'root-models/tableorder';

export type EmpresasPropType = {};

/**
 * Tela de listagem de empresas
 *
 * @author Gabriela Farias <gabriela.farias@bubotech.com.br>
 * @author Carlos Bageston <carlos.bageston@bubotech.com.br>
 * @param {EmpresasPropType} props
 */
function Empresas(props: EmpresasPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const history = useNavigate();
  const theme = useTheme();

  const api = new EmpresaAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nmEmpresa', orderType: 'ASC' });
  const [dataFiltered, setDataFiltered] = useState<Empresa[]>([]);
  const [selected, setSelected] = useState<Empresa | undefined>();
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Empresa');
    if (ScreensKeysEnum.EMPRESA in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.EMPRESA].filters, ['stEmpresa'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function handleChangePage(page: number) {
    params.current = { ...params.current, page: page + 1 };
    loadData();
  }

  function handleChangeOrder(order: TableOrder[]) {
    if (order.length)
      params.current = { ...params.current, orderField: order[0].colId, orderType: order[0].sort?.toUpperCase() };
    else
      params.current = { ...params.current, orderField: 'nmEmpresa', orderType: 'ASC' };

    loadData();
  }

  function handleFilterChange(data: any[]) {
    const staticFields = buildQuery(data, ['stEmpresa'], []);
    params.current = { ...params.current, staticParams: staticFields, page: 1 };
    loadData();
  }

  function loadData() {
    appLayoutActions.setLoading(true);
    api
      .findByPage(params.current)
      .then(res => {
        setPageData(res.data);
        setDataFiltered(res.data.data as Empresa[]);
      })
      .catch(err => {
        setDataFiltered([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <main className={classes.rootListagem}>
      <section id='filtros'>
        <CardFilters
          screenKey={ScreensKeysEnum.EMPRESA}
          data={pageData?.data ?? []}
          onLoadData={loadData}
          onRefreshFilters={setDataFiltered}
          changeFilters={handleFilterChange}
          filtersDefinitions={[
            {
              field: 'nmEmpresa',
              label: 'Nome',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'nrCnpj',
              label: 'CNPJ',
              type: TipoFiltroEnum.STRING
            },
            {
              field: 'stEmpresa',
              label: 'Status',
              selectableForSorting: false,
              type: TipoFiltroEnum.ENUM,
              enumOptions: [
                {
                  label: 'Ativo',
                  value: SituacaoEnum.ATIVO
                },
                {
                  label: 'Inativo',
                  value: SituacaoEnum.INATIVO
                },
                {
                  label: 'Bloqueado',
                  value: SituacaoEnum.BLOQUEADO
                }
              ]
            }
          ]}
        />
      </section>

      <section className={classes.sectionTable}>
        <GroupButtonGrid
          showAdd={false}
          showEdit
          showDelete={false}
          disableEdit={!selected}
          disableDelete={!selected}
          buttonEditProps={{
            disabledColor: theme.palette.grey[100],
            backgroundColor: theme.palette.primary.main
          }}
          onClickEdit={() => {
            if (!selected) return;
            history(`/cadastros/empresa/editar/${selected.idEmpresa}`);
          }}
        />

        <div className={`${classes.containerDataTable} tabela`}>
          <DataTable<Empresa>
            columns={[
              {
                field: 'nmEmpresa',
                headerName: 'Nome',
                col: 8,
                valueGetter: node => node.data.nmEmpresa ?? '-'
              },
              {
                field: 'nrCnpj',
                headerName: 'CNPJ',
                col: 2,
                valueGetter: node => node.data.nrCnpj ?? '-'
              },
              {
                valueGetter: node => {
                  switch (node.data.stEmpresa) {
                    case 1:
                      return 'Ativo';
                    case 0:
                      return 'Inativo';
                    case 3:
                      return 'Bloqueado';
                    default:
                      return 'Ativo';
                  }
                },
                col: 1,
                headerName: 'Status',
                field: 'stEmpresa'
              }
            ]}
            data={dataFiltered}
            onSelectRow={setSelected}
            onChangePage={handleChangePage}
            onOrderChange={handleChangeOrder}
            rowsPerPageEnabled={false}
            paginationConfigs={{
              labelDisplayedRows: ({ from, to }) =>
                `${from} - ${to} de ${pageData?.totalRegistros ?? 1} `,
              count: pageData?.totalRegistros ?? 0
            }}
          />
        </div>

        <ButtonFAB onClick={() => history('/cadastros/empresa/novo')}>
          <AddIcon />
        </ButtonFAB>
      </section>
    </main>
  );
}

export default Empresas;
