/**
 * Enum de Integração SMS
 *
 * @author Carlos Bageston
 */
enum IntegracaoSms {
    
    NENHUM,

    FIREBASE,

    PINPOINT,

    TWILIO,

    ZENVIA

  }
  
  export default IntegracaoSms;